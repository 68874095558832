/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Contact Carpentry
		Date:		24th July 2018
		Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */

	$color-black: rgb(35, 31, 31);
	$color-white: rgb(255, 255, 255);
	$color-blue-light: rgb(55, 173, 248);
	$color-blue-dark: rgb(7, 93, 148);
	$color-grey: rgb(149, 148, 148);

	$font-default: 'Lato', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-black;
		font-family: $font-default;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;
	}

	h1
	{
		margin: 0 0 1.5rem 0;
	}

	h2,
	h3,
	h4,
	h5
	{
		margin: 1.5rem 0;
	}

	h6
	{
		margin: 1.5rem 0 0.2rem 0;
		font-weight: 900;
	}

	a
	{
		color: $color-blue-light;
	}

		a:hover
		{
			color: $color-blue-dark;
		}

	.btn
	{
		border: none;
		border-radius: 0;
	}

	.btn-primary
	{
		background-color: $color-blue-light;
	}

		.btn-primary:focus,
		.btn-primary:active,
		.btn-primary:hover
		{
			background-color: $color-blue-dark;
		}

	img
	{
		max-width: 100%;
	}

	img.svg-responsive
	{
		width: 100% \9;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	.title-icon
	{
		display: inline-block;
		position: relative;
	}

		.title-icon:before
		{
			content: ' ';
			position: absolute;
			top: -18px;
			right: -40px;
			width: 80px;
			height: 35px;

			background-image: url(../images/house-icon.svg);
			background-repeat: no-repeat;
		}

	.grecaptcha-badge
	{
		float: left;
		margin-bottom: 20px;
	}

	/* Large desktop */
	@media (min-width: 1540px){
		.grecaptcha-badge
		{
			float: right;
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.grecaptcha-badge
		{
			float: left;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.grecaptcha-badge
		{
			float: left;
			width: 100% !important;
			clear: both;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

		//Bootstrap - hide only mobile
		.d-xs-none
		{
			display: none;
		}

		.grecaptcha-badge
		{
			float: left;
			width: 100% !important;
			clear: both;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header-wrapper
	{
		padding: 3rem;
		padding-bottom: 0;
	}

	.header
	{
		position: absolute;
		width: calc(100% - 6rem);
		top: 0;
		left: 0;
		z-index: 2000;
		padding: 2rem;
		margin: 3rem;
	}

	.swiper-container
	{
		width: 100%;

		.slider-cta
		{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: inline-block;
			z-index: 2000;
			padding: 1rem 2rem;

			background-color: $color-blue-light;

			color: $color-white;
			font-size: 1.5rem;
			font-weight: bold;
		}

		.slider-cta:hover
		{
			background-color: $color-blue-dark;

			text-decoration: none;
		}
	}

	.swiper-container .swiper-slide img
	{
		width: 100%;
	}

	.subpage-banner
	{
		position: relative;
	}

		.subpage-banner:before,
		.swiper-container .swiper-slide:before
		{
			position: absolute;
			content: ' ';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			background-color: transparentize($color-black, 0.45);
		}

		.subpage-banner img
		{
			width: 100%;
		}

		.subpage-banner-title
		{
			position: absolute;
			bottom: 2rem;
			left: 4rem;
			right: 4rem;
			z-index: 3000;

			color: $color-white;
		}

			.subpage-banner-title h5
			{
				margin: 0 0 0.5rem 0;

				text-transform: uppercase;
				font-size: 1.5rem;
			}

			.subpage-banner-title h1
			{
				text-transform: uppercase;
				font-weight: 900;
				font-size: 3rem;
			}

			.swiper-container .subpage-banner-title h1
			{
				font-weight: 400;
				font-size: 2.2rem;
			}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.swiper-container
		{
			.slider-cta
			{
				font-size: 1.1rem;
			}
		}

		.subpage-banner-title
		{
			left: 2rem;
			right: 2rem;
			bottom: 2rem;
		}

		.subpage-banner .subpage-banner-title h5
		{
			font-size: 1.0rem;
		}

		.subpage-banner .subpage-banner-title h1
		{
			margin: 0;

			font-size: 2.5rem;
		}

		.swiper-container .subpage-banner-title h1
		{
			font-size: 1.8rem;
		}

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.header
		{
			position: relative;
			padding: 2rem 0 0 0;
			margin: 0;
			width: 100%;

			background-color: $color-black;

			text-align: center;
		}

		.swiper-container
		{
			.slider-cta
			{
				top: 40%;

				font-size: 1.1rem;
			}
		}

		.subpage-banner-title
		{
			left: 2rem;
			right: 2rem;
			bottom: 2rem;

			text-align: center;
		}

		.subpage-banner .subpage-banner-title h5
		{
			font-size: 1.0rem;
		}

		.subpage-banner .subpage-banner-title h1
		{
			margin: 0;

			font-size: 2.5rem;
		}

		.swiper-container .subpage-banner-title h1
		{
			font-size: 1.6rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header-wrapper
		{
			padding: 0;
		}

		.header
		{
			position: relative;
			padding: 2rem 1.5rem;
			margin: 0;
			width: 100%;

			background-color: $color-black;

			text-align: center;
		}

		.swiper-container
		{
			.slider-cta
			{
				display: none;
			}
		}

		.subpage-banner-title
		{
			left: 2rem;
			right: 2rem;
			bottom: 2rem;
			padding-right: 2rem;
		}

		.subpage-banner .subpage-banner-title h5
		{
			font-size: 1.0rem;
		}

		.subpage-banner .subpage-banner-title h1
		{
			margin: 0;

			font-size: 1.7rem;
		}

		.swiper-container .subpage-banner-title
		{
			bottom: 1rem;
		}

		.swiper-container .subpage-banner-title h1
		{
			font-size: 1.2rem;
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-dark
	{
		padding: 0;
		margin: 0;

		border-radius: 0;
	}

		.navbar-dark .navbar-collapse
		{
			justify-content: flex-end;
		}

		.navbar-dark .navbar-nav
		{
			justify-content: flex-end;
		}

		.navbar-dark .navbar-nav .nav-link
		{
			padding: 0 1.5rem;

			text-transform: uppercase;
			color: $color-white;
		}

		.navbar-dark .navbar-nav .active .nav-link
		{
			color: $color-blue-light;
		}

		.navbar-dark .navbar-nav .dropdown-menu
		{
			padding: 0;
			border-radius: 0;
			border: none;
		}

		.navbar-dark .navbar-nav .dropdown-menu .dropdown-item
		{
			text-align: center;
		}

		.dropdown-item.active, .dropdown-item:active
		{
			background-color: $color-blue-light;
		}

		.navbar-dark .navbar-toggler
		{
			width: 100%;
			margin-top: 2rem;
		}

	/*  desktop */
	@media (min-width: 768px) {
		.navbar-dark .navbar-nav .dropdown-menu
		{
			left: 50%;
			transform: translateX(-50%);

			border-radius: 0;
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.navbar-dark .navbar-nav .nav-link
		{
			padding: 0 1.0rem;

			font-size: 0.9rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-dark
		{
			margin: 2rem 0;
		}

		.navbar-dark .navbar-collapse
		{
			justify-content: start;
		}

		.navbar-expand-md .navbar-nav
		{
			width: 100%;

			justify-content: space-evenly;
		}

		.navbar-dark .navbar-nav .nav-link
		{
			padding: 0 1.0rem;

			font-size: 0.9rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-dark .navbar-nav
		{
			border: 1px solid rgba(255, 255, 255, 0.1);
		}

			.navbar-dark .navbar-nav .nav-link
			{
				padding: 0.75rem 0;
			}

			.dropdown-item
			{
				padding: 0.5rem 0.5rem;
			}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 4rem;
	}

	.contactBanner {
		width: 100%;
		padding: 0 1rem;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		z-index: 3000;
		color: white;

	}

		.contactBanner h5
		{
			margin: 0 0 0.5rem 0;

			text-transform: uppercase;
			font-size: 1.5rem;
		}

		.contactBanner h1
		{
			text-transform: uppercase;
			font-weight: 900;
			font-size: 3.4rem;

			line-height: 1em;
		}

	.gallery
	{
		display: flex;
		flex-wrap: wrap;

		align-content: stretch;
	}

		.gallery div
		{
			display: flex;
			flex-grow: 1;
			min-height: 250px;
			position: relative;
			padding: 1.5rem;

			background-size: cover;
			background-position: center;

			color: $color-white;
			font-size: 1.1rem;
		}

			.gallery .gallery-all
			{
				flex-basis: 25%;
				
				a:first-of-type
				{
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
			}

			.gallery div.item a:first-of-type
			{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;

				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+71,231f1f+99&0+71,0.6+100 */
				background: -moz-linear-gradient(top, rgba(255,255,255,0) 71%, rgba(35,31,31,0.58) 99%, rgba(35,31,31,0.6) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, rgba(255,255,255,0) 71%,rgba(35,31,31,0.58) 99%,rgba(35,31,31,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, rgba(255,255,255,0) 71%,rgba(35,31,31,0.58) 99%,rgba(35,31,31,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#99231f1f',GradientType=0 ); /* IE6-9 */

				-webkit-transition: all 150ms ease-in-out;
				-moz-transition: all 150ms ease-in-out;
				-ms-transition: all 150ms ease-in-out;
				-o-transition: all 150ms ease-in-out;
				transition: all 150ms ease-in-out;
			}

			.gallery div.item:hover
			{
				text-decoration: none;
			}

			.gallery div.item:hover a:first-of-type
			{
				background-color: transparentize($color-blue-light, 0.5);
			}

		.gallery div.gallery-1
		{
			flex-grow: 5;
		}

		.gallery div.gallery-2
		{
			flex-grow: 3;
		}

		.gallery .third-forth-wrapper
		{
			display: flex;
			flex-flow: column;
			padding: 0;
		}

		.gallery .third-forth-wrapper a.gallery-3,
		.gallery .third-forth-wrapper a.gallery-4
		{
			flex-basis: 100%;
			min-height: 250px;
			flex-grow: 0;
		}

		.gallery span
		{
			z-index: 100;
			align-self: flex-end;
			padding: 4px;

			background-color: transparentize($color-black, 0.2);
		}

	.form-control
	{
		border-radius: 0;
	}

	.feature-nav
	{
		width: 100%;
		padding: 0 3rem;
		display: flex;
		flex-flow: row;
		flex-wrap: wrap;
	}

		.feature-nav-item
		{
			position: relative;
			flex-basis: 33.33%;
			width: 33.33%;
			display: flex;
			padding: 6rem 1.5rem 1.5rem 1.5rem;

			background-size: cover;
			background-position: center;

			text-transform: uppercase;
			font-size: 1.4rem;
			color: $color-white;
		}

			.feature-nav-item .content
			{
				z-index: 100;
			}

			.feature-nav-item:before
			{
				position: absolute;
				content: ' ';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 1;

				background-color: transparentize($color-blue-dark, 0.4);
				-webkit-transition: all 150ms ease-in-out;
				-moz-transition: all 150ms ease-in-out;
				-ms-transition: all 150ms ease-in-out;
				-o-transition: all 150ms ease-in-out;
				transition: all 150ms ease-in-out;
			}

			.feature-nav-item:hover
			{
				text-decoration: none;

				color: $color-white;
			}

				.feature-nav-item:hover:before
				{
					background-color: transparentize($color-black, 0.4);
				}

	blockquote
	{
		padding: 1.2rem 2rem;
		position: relative;
	}

		blockquote:before
		{
			content: ' ';
			position: absolute;
			width: 32px;
			height: 16px;
			top: 0;
			left: 0;

			background-image: url(../images/quote-icon.svg);
			background-repeat: no-repeat;
		}

		blockquote:after
		{
			content: ' ';
			position: absolute;
			width: 32px;
			height: 16px;
			bottom: 0;
			right: 0;

			background-image: url(../images/quote-icon.svg);
			background-repeat: no-repeat;
		}

	.featured-projects
	{
		margin-top: 3rem;
		padding: 0 0 3rem 0;

		background-color: $color-black;

		color: $color-grey;
	}

		.featured-projects h2
		{
			color: $color-white;
		}



	/* Large desktop */
	@media (min-width: 1200px) {
		.page
		{
			padding: 4rem 6rem;
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.gallery div
		{
			flex-grow: 1;
			min-height: 300px;
		}

		.gallery .third-forth-wrapper a.gallery-3,
		.gallery .third-forth-wrapper a.gallery-4
		{
			min-height: 150px;
		}

		.gallery .gallery-all
		{
			flex-basis: 33.33%;
		}

		.contactBanner h5
		{
			font-size: 1.2rem;
		}

		.contactBanner h1
		{
			font-size: 2.5rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.gallery
		{
			flex-wrap: wrap;
			justify-content: stretch;
		}

		.gallery div
		{
			flex-grow: 1;
			min-height: 300px;
		}

		.gallery div.primary
		{
			flex-grow: 1;
		}

		.gallery div.secondary
		{
			flex-grow: 1;
		}

		.gallery .third-forth-wrapper
		{
			flex-flow: row;
			width: 100%;
		}

		.gallery .third-forth-wrapper a.gallery-3,
		.gallery .third-forth-wrapper a.gallery-4
		{
			min-height: 300px;
			flex-grow: 1;
		}

		.feature-nav-item
		{
			font-size: 1.1rem;
		}

		.gallery .gallery-all
		{
			flex-basis: 33.33%;
		}

		.contactBanner
		{
			top: 40%;
		}

		.contactBanner h5
		{
			font-size: 1rem;
		}

		.contactBanner h1
		{
			font-size: 2rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.page
		{
			padding: 2rem 1.5rem;
		}

		.gallery
		{
			flex-wrap: wrap;
			justify-content: stretch;
		}

		.gallery div
		{
			flex-grow: 1;
			min-height: 250px;
		}

		.gallery .gallery-all
		{
			flex-basis: 100%;
		}

		.gallery div.primary
		{
			flex-grow: 1;
		}

		.gallery div.secondary
		{
			flex-grow: 1;
		}

		.gallery .third-forth-wrapper
		{
			flex-flow: column;
			width: 100%;
		}

		.gallery .third-forth-wrapper a.gallery-3,
		.gallery .third-forth-wrapper a.gallery-4
		{
			min-height: 250px;
			flex-grow: 0;
		}

		.feature-nav
		{
			width: 100%;
			padding: 0;
			display: block;
		}

			.feature-nav-item
			{
				position: relative;
				flex-basis: auto;
				width: 100%;
				display: block;
				padding-left: 2rem;

				border-top: 1px solid $color-blue-light;

				text-transform: uppercase;
				font-size: 1.2rem;
			}

			.feature-nav-item .content
			{
				position: relative;
			}

		.featured-projects
		{
			margin-top: 0;
		}

		.contactBanner {
			display: none;
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer-banner
	{
		width: 100%;
		height: 15rem;

		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.footer
	{
		width: 100%;
		padding: 4rem 4rem;

		background-color: $color-black;

		color: $color-grey;
		font-size: 0.7rem;
		text-transform: uppercase;

		a
		{
			color: $color-grey;
		}

		a:hover
		{
			color: $color-blue-light;
			text-decoration: none;
		}

		.footnotes
		{
			opacity: 0.5;
		}

		.nav .nav-link:first-child
		{
			padding-left: 0;
		}

		.nav .nav-link:last-child
		{
			padding-right: 0;
		}

		.btn-primary
		{
			color: $color-white;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {
		.footer
		{
			padding: 4rem 6rem;;
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			padding: 2rem 1.5rem;

			text-align: center;

			.nav
			{
				margin: 2rem 0;
			}

			.nav .nav-link
			{
				padding: 1rem 0;
			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}